import { CategoryBrief } from "@trunkery/internal/lib/vatureTypes";
import { Dict } from "@lana-commerce/core/commonTypes";
import { categoryLink } from "utils/categoryLink";

interface ProductLike {
  handle: string;
}

export function productLink(
  product: ProductLike | null | undefined,
  categoryID: string,
  categoryBriefs: Dict<CategoryBrief>
) {
  if (!product) return "";
  if (!categoryID) {
    return `/products/${product.handle}`;
  } else {
    return `${categoryLink(categoryID, categoryBriefs)}/products/${product.handle}`;
  }
}
