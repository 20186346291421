import React from "react";
import { CategoryFragment } from "@trunkery/internal/lib/vature-gen/types";
import { Environment } from "@trunkery/internal/lib/environment";
import { Link } from "gatsby";
import { VariantLike, analyticsProductData } from "@trunkery/internal/lib/analyticsProductData";
import { productLink } from "utils/productLink";
import { useEnvironment } from "@trunkery/internal/lib/environmentContext";
import { useSiteData } from "utils/useSiteData";

function analyticsVariantClicked(env: Environment, variant: VariantLike | null) {
  if (!variant) return;
  env.analytics("track", "Product Clicked", analyticsProductData(env, env.shopCurrency, variant));
}

interface VariantAnchorProps {
  variant: VariantLike | null;
  category?: CategoryFragment;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export const VariantAnchor = (props: VariantAnchorProps) => {
  const { categoryBriefs } = useSiteData();
  const env = useEnvironment();
  return (
    <Link
      className={props.className}
      to={productLink(props.variant?.product, props.category?.id || "", categoryBriefs)}
      onClick={() => {
        props.onClick?.();
        analyticsVariantClicked(env, props.variant);
      }}
    >
      {props.children}
    </Link>
  );
};
