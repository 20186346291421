import { CachedLoader } from "utils/cachedLoader";
import { useEffect, useState } from "react";

export function useAsyncCachedValue<T>(loader: CachedLoader<T>) {
  const [value, setValue] = useState<T | undefined>(loader.currentValue());
  useEffect(() => {
    if (!value) {
      loader.load().then(setValue);
    }
  }, []);
  return value;
}
