export const paths = {
  account: "/account",
  accountAddresses: "/account/addresses",
  accountConfirm: (token: string) => `/account/confirm/${token}`,
  accountOrder: (orderID: string) => `/account/orders/${orderID}`,
  accountOrders: "/account/orders",
  accountPasswordReset: "/account/password-reset",
  accountPaymentMethods: "/account/payment-methods",
  accountProfile: "/account/profile",
  accountSignin: "/account/signin",
  accountSigninWithReturn: (returnTo: string) => `/account/signin?returnTo=${encodeURIComponent(returnTo)}`,
  accountSignup: "/account/signup",
  accountStoreCredit: "/account/store-credit",
};
