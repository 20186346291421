import React from "react";
import { CartButtonAsync } from "components/CartButtonAsync";
import { Link } from "gatsby";
import { SearchButtonAsync } from "components/SearchButtonAsync";
import { UserMenuButtonAsync } from "components/UserMenuButtonAsync";

import { T } from "./FixedHeader.tlocale";

interface FixedHeaderProps {
  setNavExpanded: (v: boolean) => void;
}

export const FixedHeader = (props: FixedHeaderProps) => (
  <div className="fixed-header">
    <div className="alert-bar">{T("Free shipping on all orders over $30")}</div>
    <div className="page-header">
      <div className="page-header__search">
        <div
          className="page-header__nav-icon"
          onClick={() => {
            props.setNavExpanded(true);
          }}
        >
          <div className="page-header__nav-icon-bar"></div>
          <div className="page-header__nav-icon-bar"></div>
          <div className="page-header__nav-icon-bar"></div>
        </div>
        <SearchButtonAsync />
      </div>
      <div className="page-header__logo">
        <Link to="/">
          <img width="164" height="29" src={require("images/vature.svg").default} alt="vature" />
        </Link>
      </div>
      <div className="page-header__buttons">
        <UserMenuButtonAsync />
        <CartButtonAsync />
      </div>
    </div>
  </div>
);
