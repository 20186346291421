import "sass/main.scss";

import React, { useEffect, useState } from "react";
import { FixedHeader } from "components/FixedHeader";
import { FixedSidebar } from "components/FixedSidebar";
import { Nav } from "components/Nav";
import { useEnvironment } from "@trunkery/internal/lib/environmentContext";

const PageView = () => {
  const env = useEnvironment();
  useEffect(() => {
    env.analytics("page");
  }, []);
  return <></>;
};

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = (props: LayoutProps) => {
  const [navExpanded, setNavExpanded] = useState(false);
  return (
    <>
      <Nav expanded={navExpanded} setExpanded={setNavExpanded} />
      <FixedHeader setNavExpanded={setNavExpanded} />
      <FixedSidebar />
      {props.children}
      <PageView />
    </>
  );
};
