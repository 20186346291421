import React from "react";
import find from "lodash/find";
import map from "lodash/map";
import { Link } from "gatsby";
import { categoryLink } from "utils/categoryLink";
import { childrenOfMenuItem } from "utils/childrenOfMenuItem";
import { topLevelMenuItems } from "utils/topLevelMenuItems";
import { useSiteData } from "utils/useSiteData";

export const SidebarContent = () => {
  const { menus, categoryBriefs } = useSiteData();
  const menu = find(menus, (m) => m.handle === "sidebar");
  if (!menu) {
    console.warn("sidebar menu not found");
    return null;
  }
  return (
    <>
      {map(topLevelMenuItems(menu.items), (section, idx) => (
        <div className="sidebar__section" key={section.id}>
          <div className="sidebar__section-header">{section.name}</div>
          {map(childrenOfMenuItem(menu.items, idx), (item) =>
            item.type === "category" ? (
              <Link
                className="sidebar__item"
                activeClassName="sidebar__item--active"
                to={categoryLink(item.category?.id || "", categoryBriefs)}
                key={item.id}
              >
                {item.name}
              </Link>
            ) : null
          )}
        </div>
      ))}
    </>
  );
};
