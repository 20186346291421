import React, { useCallback, useEffect } from "react";
import { cartModule } from "modules/cartModule";
import { environmentFromSiteData } from "utils/environmentFromSiteData";
import { globalAuthState } from "utils/globalAuthState";
import { useAsyncCachedValue } from "utils/useAsyncCachedValue";
import { useSiteData } from "utils/useSiteData";

export const CartButtonAsync = () => {
  const siteData = useSiteData();
  const env = environmentFromSiteData(siteData);
  const m = useAsyncCachedValue(cartModule);
  const onClick = useCallback(() => {
    if (m) m.show();
  }, [m]);
  useEffect(() => {
    if (!m) return;
    m.init(globalAuthState.auth, env);
  }, [m, globalAuthState.auth]);
  return (
    <>
      <div className="bag-button-container page-header__button" onClick={onClick}>
        {m ? m.renderCount() : <div className="bag-button-container__count" />}
        <img width="24" height="24" src={require("images/bag-2.svg").default} />
      </div>
      {m ? m.render() : null}
    </>
  );
};
