import React, { useCallback } from "react";
import { navigate } from "gatsby";
import { paths } from "utils/paths";
import { useAsyncCachedValue } from "utils/useAsyncCachedValue";
import { userMenuModule } from "modules/userMenuModule";

export const UserMenuButtonAsync = () => {
  const m = useAsyncCachedValue(userMenuModule);
  const onClick = useCallback(() => {
    if (m) m.show();
  }, [m]);
  return (
    <>
      <img
        onClick={() => {
          navigate(paths.account);
        }}
        width="22"
        height="24"
        className="page-header__button mobile-only"
        src={require("images/user-3.svg").default}
        id="the-mobile-user-button"
      />
      <img
        onClick={onClick}
        width="22"
        height="24"
        className="page-header__button desktop-only"
        src={require("images/user-3.svg").default}
        id="the-desktop-user-button"
      />
      {m ? m.render() : null}
    </>
  );
};
