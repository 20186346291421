import React from "react";
import { SidebarContent } from "components/SidebarContent";

export const FixedSidebar = () => {
  return (
    <div className="fixed-sidebar">
      <div className="sidebar">
        <SidebarContent />
      </div>
    </div>
  );
};
