import React from "react";
import { RootCloseWrapper } from "@lana-commerce/core/react/components/RootCloseWrapper";
import { SidebarContent } from "components/SidebarContent";

interface NavProps {
  expanded: boolean;
  setExpanded: (v: boolean) => void;
}

export const Nav = (props: NavProps) => {
  const close = () => {
    props.setExpanded(false);
  };
  return props.expanded ? (
    <div className="nav">
      <RootCloseWrapper onRootClose={close}>
        <div className="nav__body">
          <img className="nav__close" src={require("images/cross-8.svg").default} onClick={close} />
          <div className="sidebar sidebar--scrollable">
            <SidebarContent />
          </div>
        </div>
      </RootCloseWrapper>
    </div>
  ) : null;
};
