export class CachedLoader<T> {
  promise: Promise<T> | undefined;
  value: T | undefined;
  constructor(private loader: () => Promise<T>) {}

  currentValue(): T | undefined {
    return this.value;
  }

  async load(): Promise<T> {
    if (!this.promise) {
      this.promise = this.loader().then((v) => {
        this.value = v;
        return v;
      });
    }
    return this.promise;
  }
}
