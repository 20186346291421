import React, { useCallback } from "react";
import { searchModule } from "modules/searchModule";
import { useAsyncCachedValue } from "utils/useAsyncCachedValue";
import { useEnvironment } from "@trunkery/internal/lib/environmentContext";

import { T } from "./SearchButtonAsync.tlocale";

export const SearchButtonAsync = () => {
  const m = useAsyncCachedValue(searchModule);
  const env = useEnvironment();
  const onClick = useCallback(() => {
    if (m) m.show();
  }, [m]);
  return (
    <>
      <a onClick={onClick}>
        <img width="20" height="20" className="page-header__search-icon" src={require("images/search-5.svg").default} />
        <div className="page-header__search-link">{T("Search")}</div>
      </a>
      {m ? m.render(env) : null}
    </>
  );
};
